import React from "react";
import { OrganizationSwitcher, useAuth, UserButton } from "@clerk/remix";

import { HomeButton } from "@/components/gen-misc";
import { ModeDropDown } from "@/components/gen-mode-toggle";
import {
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarTrigger,
} from "./ui/sidebar";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarRail,
  useSidebar,
} from "@/components/ui/sidebar";
import {
  CloudCog,
  Download,
  MonitorCog,
  PiggyBank,
  UserRoundCog,
} from "lucide-react";
import { Link } from "@remix-run/react";
import { NEW_ORGANIZATION_URL, ORGANIZATION_SETTINGS_URL } from "@/urls";

const applicationMenuItems = [
  {
    title: "Download",
    url: "/download",
    icon: Download,
  },
];

const settingsMenuItems = [
  {
    title: "Profile",
    url: "/settings/profile",
    icon: UserRoundCog,
  },
  {
    title: "Organization",
    url: "/settings/organization",
    icon: MonitorCog,
  },
  {
    title: "Api keys",
    url: "/settings/api-keys",
    icon: CloudCog,
  },
  {
    title: "Billing",
    url: "/settings/billing",
    icon: PiggyBank,
  },
];

export const AppSidebar = () => {
  const { open } = useSidebar();
  return (
    <Sidebar collapsible="icon">
      <SidebarHeader>
        <OrganizationSwitcher
          appearance={{
            elements: {
              organizationPreviewTextContainer__organizationSwitcherTrigger:
                open ? "block" : "hidden",
              organizationSwitcherTriggerIcon: open ? "block" : "hidden",
            },
          }}
          createOrganizationMode="navigation"
          createOrganizationUrl={NEW_ORGANIZATION_URL}
          hidePersonal
          organizationProfileMode="navigation"
          organizationProfileUrl={ORGANIZATION_SETTINGS_URL}
        />
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel>Application</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              {applicationMenuItems.map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton asChild>
                    <Link prefetch="intent" to={item.url}>
                      <item.icon />
                      <span>{item.title}</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup>
          <SidebarGroupLabel>Settings</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              {settingsMenuItems.map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton asChild>
                    <Link prefetch="render" to={item.url}>
                      <item.icon />
                      <span>{item.title}</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <div className="mb-4 flex w-full justify-center">
          <UserButton
            showName={open}
            userProfileMode="navigation"
            userProfileUrl="/settings/profile"
          />
        </div>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
};
const ShellShell = ({
  Header,
  Main,
}: {
  readonly Header: React.ComponentType;
  readonly Main: React.ComponentType;
}) => {
  return (
    <div className="flex h-full w-full flex-col">
      <header className="bg-muted sticky top-0 z-30 flex flex-col items-center px-4 pb-2 pt-2 sm:static">
        <div className="flex h-10 w-full items-center">
          <Header />
        </div>
      </header>
      <main className="h-[calc(100dvh-6rem)]">
        <Main />
      </main>
    </div>
  );
};

const MidFlex = ({ children }: { readonly children?: React.ReactNode }) => {
  return (
    <div className="ml-auto mr-auto flex flex-1 grow-0 gap-1">
      {children ?? null}
    </div>
  );
};

const reactNodeToFC =
  (children: React.ReactNode): React.FC =>
  () => {
    return children;
  };

export const AppLoggedInMinShell = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => {
  return (
    <ShellShell
      Header={reactNodeToFC(
        <>
          <HomeButton />
          <MidFlex />
          <UserButton />
          <div className="w-2" />
          <ModeDropDown />
        </>,
      )}
      Main={reactNodeToFC(children)}
    />
  );
};

export const AppSidebarShell = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => {
  return (
    <SidebarProvider>
      <AppSidebar />
      <div className="flex h-full w-full flex-col">
        <div className="flex h-[6rem] p-1">
          <SidebarTrigger />
          <MidFlex />
          <ModeDropDown />
        </div>
        <main className="h-[calc(100dvh-6rem)]">{children}</main>
      </div>
    </SidebarProvider>
  );
};

export const AppLoggedOutMinShell = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => {
  return (
    <ShellShell
      Header={reactNodeToFC(
        <>
          <HomeButton />
          <MidFlex />
          <ModeDropDown />
        </>,
      )}
      Main={reactNodeToFC(children)}
    />
  );
};

export const AppShell = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => {
  const { isSignedIn } = useAuth();
  if (isSignedIn) {
    return <AppLoggedInMinShell>{children}</AppLoggedInMinShell>;
  } else {
    return <AppLoggedOutMinShell>{children}</AppLoggedOutMinShell>;
  }
};
